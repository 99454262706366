import React from "react";
import styled from "styled-components";



export default function ThreeItemCard({img,title,subtitle,desc,special}) {
 
  return (

      <>
    <LogoWrapper className="p-y10">
        <ImgStyle src={img} alt="client logo" />
        <span className="semiBold">{title}</span>
        <p className='regular font15 greyColor m-t10'>{subtitle}</p>
      
        <p className='regular font13 greyColor m-t10'>{desc}</p>
        <p className='regular font13 darkGreyColor m-t10'>{special}</p>

      </LogoWrapper></>
     
  )

}

const LogoWrapper = styled.div`
  
  text-align:center;
  

  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  margin:auto;
`;
const ClientName = styled.p`
width: 100%;
height: 100%;

padding: 10%;
`;

const ClientDesc = styled.p`
display:block;
`