import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "./Contact";
import Footer from "../components/Sections/Footer"
import MainNavbar from "../components/Nav/MainNavbar";
// Css
import '../style/extra.css'

export default function Landing() {
  return (
    <>
    
   

      <Header />
      <Services />
      {/* <Projects />
      <Blog />
      <Pricing />*/}
      {/* <Contact />*/}
     
    </>
  );
}


