import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
// import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
// Icons
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { NavLink } from "react-router-dom";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
   
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };

  }, [y]);


  return (
    <>
      {/* <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} /> */}
      {/* {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />} */}
      <Wrapper className="flexCenter animate lightBg" style={y > 100 ? { height: "30px" } : { height: "35px" }}>
        <NavInner className="container flexSpaceCenter">
        
          <UlWrapper className="at-left"> 
       
            </UlWrapper>
          <UlWrapperRight className="">
          <li className="semiBold font12 m-t3" style={{display:'flex'}}> 
            {/* <a href="/">
                <CallIcon />
              </a> */}
            <p class="extraBold tomatoColor font15 letter-spacing" style={{alignSelf: 'center',
    marginRight: '15px',
    
    marginBottom: '0px',}}>+91 94224 09492</p>
           
            </li>
            <li className="semiBold font18 pointer flexCenter m-t5">
            <NavLink className="radius8 pointer orangeColor" to='/Contact'>
            <LocationOnIcon />
        </NavLink>
              
             
            </li>
            
          </UlWrapperRight>
        </NavInner>
      
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const UlWrapper = styled.ul`
  display: flex;
  
`;
const UlWrapperRight = styled.ul`
 
    display: flex;
  
`;


