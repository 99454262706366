import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../assets/img/contact-1.png";
import ContactImg2 from "../assets/img/contact-2.png";
import ContactImg3 from "../assets/img/contact-3.png";

// Section
import TopNavbar from "../components/Nav/TopNavbar";
import MainNavbar from "../components/Nav/TopNavbar";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";

//CSS
import "../style/pages.css";

import "../style/elements.css";
import axios from "axios";
import { Call } from "@mui/icons-material";

export default function Contact() {
  // Use an object to store the form data instead of an array
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  // Update the state with the input value for each field
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setSubmitted(false);
  }, []);
  // Send a post request to the node js backend with the form data
  const handleSubmit = (e) => {
    // Prevent the default behavior of the form
    e.preventDefault();
    console.log(data);
    axios
      .post("http://localhost:5000/api/contact", data)
      .then((response) => {
        // Handle the response from the backend
        console.log(response.data);
        // Clear the form data
        setData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        if (response.data.status == "ok") {
          setSubmitted(true);
          setTimeout(() => {
            setSubmitted(false);
          }, 12000);
        }
      })
      .catch((error) => {
        // Handle the error from the backend
        console.error(error);
      });
  };
  return (
    <>
      <Grid container className="lightBg contact--container flexCenter">
        <Grid item lg={10} md={10} sm={10} xs={10} style={{ width: "100%" }}>
          <Box style={{ width: "100%" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d477.38607368155886!2d74.6434004695718!3d16.82196068271559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1230773dff2e9%3A0xa71a5edeb52b2e4f!2sInnovative%20Computer!5e0!3m2!1sen!2sin!4v1702700518558!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Grid>

        <Grid item lg={4} md={5} sm={6} xs={10}>
          <Box style={{ width: "100%" }}>
            <p className="font16 regular darkGreyColor">
              Address -: S3, Vedika Heights, Shivaji, Miraj 416410
            </p>

            <p className="font16 semiBold darkColor m-t10">
              Mr. Shaukatali Mujawar <br />
              <Call fontSize="0.580rem" className="p-t5" /> +91 94224 09492
              <br />
              Mr. Mazil Khatib <br />
              <Call fontSize="0.580rem" className="p-t5" /> +91 88305 51301
            </p>
            <p className="font16 semiBold darkColor ">
              Email - support@innoinfotech.in
            </p>
          </Box>
        </Grid>
        <Grid item lg={4.5} md={5.5} sm={5.5} xs={10} style={{ width: "100%" }}>
          <Box style={{ width: "100%" }}>
            <h1 className="font40 extraBold">Connect us</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}

            <Stack
              style={{ width: "inherit" }}
              spacing={2}
              component="form"
              className="contact--form"
              onSubmit={handleSubmit} // Add the onSubmit prop to the form element
            >
              {submitted && (
                <span className="tostify tostify-success darkColor font15 semiBold">
                  sucssefully submited!!
                </span>
              )}
              <TextField
                required
                id="outlined-required"
                label="Full Name"
                name="name" // Add the name prop to each input field
                onChange={handleChange}
                value={data.name}
              />
              <TextField
                required
                id="outlined-required"
                type="email"
                label="Email"
                name="email"
                onChange={handleChange}
                value={data.email}
              />
              <TextField
                required
                id="outlined-required"
                label="Phone No."
                name="phone"
                onChange={handleChange}
                type="number"
                value={data.phone}
              />
              <TextField
                id="outlined-multiline-flexible"
                label="Message"
                multiline
                maxRows={4}
                name="message"
                onChange={handleChange}
                value={data.message}
              />

              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
