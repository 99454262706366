import React from "react";
import Slider from "react-slick";
// Assets
import ClientLogo00 from "../../assets/img/clients/logo00.png"
import ClientLogo01 from "../../assets/img/clients/logo01.png"
import ClientLogo02 from "../../assets/img/clients/logo02.png";
import ClientLogo03 from "../../assets/img/clients/logo03.png";
import ClientLogo04 from "../../assets/img/clients/logo04.png";
// import ClientLogo05 from "../../assets/img/clients/logo05.svg";
// import ClientLogo06 from "../../assets/img/clients/logo06.svg";
import {Typography} from '@mui/material'
import ThreeItemCard from "./ThreeItemCard";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    autoplay: true,
      autoplaySpeed: 1000,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 265,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const ClientData=[
    {
      img:ClientLogo00,
      title:`NEET-JEE Online Practice Test`,
      desc:`Explore and experience JEE sectional tests and full-length mocks, followed by an in-depth performance analysis, all served free to help you ramp up your preparation for cracking NEET JEE`,
      special:``
    },
    {
      img:ClientLogo02,
      title:`Jawahar Arts,Science and Commerce College`,
      desc:`Explore and experience JEE sectional tests and full-length mocks, followed by an in-depth performance analysis, all served free to help you ramp up your preparation for cracking NEET JEE`,
      special:``
    },
    {
      img:ClientLogo03,
      title:`Fly-High Cafe & Restarunt`,
      desc:`Explore and experience JEE sectional tests and full-length mocks, followed by an in-depth performance analysis, all served free to help you ramp up your preparation for cracking NEET JEE`,
      special:``
    },
    {
      img:ClientLogo04,
      title:`Tuljabhavani Arts and Commerce College`,
      desc:`Explore and experience JEE sectional tests and full-length mocks, followed by an in-depth performance analysis, all served free to help you ramp up your preparation for cracking NEET JEE`,
      special:``
    },
    {
      img:ClientLogo01,
      title:`Smt. Akkatai Patil Kanya College`,
      desc:`College Website Development - Fully featured Website for college. College website developers offers great value when you want a good college website design.`,
      special:`Ichlkaranji Dist.-Kolhapur [M.H.]`
    },
  ]
  return (
    <div>
      <Slider {...settings}>
      
{
  ClientData.map((item,i)=>{
    return(
      <ThreeItemCard key={i} img={item.img} title={item.title} desc='' special='' />
    )
  })
}
       

       
        
{/*         

     
      <ClientCard 
        img={ClientLogo02}
        title={`Jawahar Arts,Science and Commerce College`} 
        info={``}
        location={`Andur Tal.-Tuljapur Dist- Osmanabad [M.H.]`} 
      />
     
      <ClientCard 
        img={ClientLogo02}
        title={`Jawahar Arts,Science and Commerce College`} 
        info={``}
        location={`Andur Tal.-Tuljapur Dist- Osmanabad [M.H.]`} 
      />

      
     
      <ClientCard 
        img={ClientLogo03}
        title={`Fly-High Cafe & Restarunt`} 
        info={``}
        location={`Sangli Dist.-Sangli [M.H.]`} 
      />

      
     
      <ClientCard 
        img={ClientLogo04}
        title={`Tuljabhavani Arts and Commerce College`} 
        info={``}
        location={`Tuljapur Tal.-Tuljapur Dist- Osmanabad [M.H.]`} 
      />

      <ClientCard 
        img={ClientLogo01}
        title={`Smt. Akkatai Patil Kanya College`} 
        info={``}
        location={`Ichlkaranji Dist.-Kolhapur [M.H.]`} 
      /> */}
      </Slider>
    </div>
  );
}
