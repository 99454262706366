import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import ServicesSlider from "../Elements/ServicesSlider";

import About1 from "../../assets/img/about/About1.JPG";
import About2 from "../../assets/img/about/About2.JPG";
import About3 from "../../assets/img/about/About3.JPG";
import About4 from "../../assets/img/about/About4.JPG";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="container">
        <h1 className="font40 extraBold">Our Services</h1>
      </div>
      <div
        className="lightBg top-bottom-lightGrey-shadow"
        style={{ padding: "30px 0", marginTop: "15px" }}
      >
        {/* <div className="container"> */}

        <ClientSlider />
        {/* </div> */}
      </div>
      {/* </div> */}
      <div className="whiteBg" style={{ paddingTop: "60px" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Software engineering"
                desc={`
                     
                Software engineering is a computer-focused career that involves developing computer programs and systems that are user-friendly, innovative, and fit the desires of customers and clients. Software engineering students learn about applying engineering principles to the development of software and computer programs used and accessed on different devices.
`}
                subtitle={`Join! Software engineering Courses
                  `}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Web Development"
                subtitle={`Create thoughtfully designed websites
                  `}
                desc={`
                  Our team incorporates extensive experience to create digitally transformative, high performing and feature-rich yet native mobile apps for different platforms, named Android, Windows. Moreover, we have professionals with vast knowledge of HTML5 development, with the help of which we create cross-platform mobile apps to work on almost every platform or device. Ability to deliver highly scalable, secure and sustainable mobile app.

                  `}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Software Solutions"
                subtitle={`
                Product Development Established Businesses

                `}
                desc="we focus on delivering world-class quality and bespoke application development solutions that expand your reach, improve your customer engagement, and take your business to the next level. We develop one-of-a-kind web and mobile applications for businesses of all sizes by harnessing the power of disruptive technologies. We create products for organisations large and small across a variety of different sectors, including dedicated cloud solutions for multiple product verticals for licensing or resale."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Mobile Apps"
                subtitle={`
                Software and Apps for Start-ups
                `}
                desc={`
              In a world dominated by mobile devices, having a powerful mobile presence is essential. Our mobile app development team specializes in creating intuitive, feature-rich applications for iOS and Android platforms. We tailor our solutions to meet your unique business needs, enhancing user engagement and providing a seamless experience.


              `}
              />
            </ServiceBoxWrapper>
            {/* <ServicesSlider /> */}
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter m-b0">
              <AddLeft>
                <h4 className="font15 semiBold">
                  An Innovative Mobile app & Web Development Company
                </h4>
                <h2 className="font40 extraBold">
                  Building Apps & web application that refined possibilities
                </h2>
                <p className="font12">
                  We pride ourselves on playing a key role in the software
                  development industry. Our software solutions are used every
                  day by some of the world’s leading organisations and more. .
                  We are a team of talented developers extremely passionate
                  about software development. To get it right for you today,
                  tomorrow and in the future, we continuously study the latest
                  trends, adapt to new solutions and use the most up to date
                  technology available. We develop smart marketing strategies
                  that create alignment, reduce costs, optimize resources, and
                  maximize return on investment. Increase ROI Increase Lead
                  Generation Increase Revenue Improve Brand Awareness Improve
                  Customer Retention
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={About1} alt="1" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={About2} alt="2" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={About3} alt="3" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={About4} alt="4" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
