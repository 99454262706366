import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// Assets
import Logo from "../../assets/img/INNOVATIVE2.jpg";
import { Grid } from "@mui/material";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Box
      sx={{ flexGrow: 1, padding: "0px 16px", paddingBottom: "20px" }}
      className="darkBg top-shadow-whiteBg"
    >
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={3}
          md={3.5}
          className="whiteBg"
          style={{
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Link to="home" smooth={true} offset={-80}> */}

          <img className="LogoImg" src={Logo} />

          {/* </Link> */}
        </Grid>
        <Grid item sm={6} xs={12} lg={4.5} md={4.25}>
          <p className="whiteColor font13"></p>
        </Grid>
        <Grid item sm={6} xs={12} lg={4.5} md={4.25}>
          <p className="whiteColor font18">Support- +91 94224 09492</p>
        </Grid>
      </Grid>
    </Box>
  );
}
