import React from "react";
import { Helmet } from "react-helmet";

// Screens
import Landing from "./screens/Landing.jsx";
import { Outlet, Route, Routes, createBrowserRouter } from "react-router-dom";
import Contact from "./screens/Contact.jsx";
import MainNavbar from "./components/Nav/MainNavbar.jsx";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import Footer from "./components/Sections/Footer.jsx";
import { Box } from "@mui/material";

export default function App() {
  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Landing />,
  //     children: [
  //       {
  //         path: "contact",
  //         element: <Contact />,
  //       },
  //     ],
  //   },
  // ]);
 
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      {/* <Outlet /> */}
      {/* <Routes>
      <Route path="/" element={<Landing topBar={true} />}>
        <Route path="contact" element={<Contact topBar={false} />} />
      </Route>
    </Routes> */}
    <Routes>
  <Route path="/" element={
  
  <Box id="home">
       <TopNavbar />
      <MainNavbar logoLink='home' />
      <Landing />
      <Footer />
      </Box>
  } />
  <Route path="Contact" element={
    <>
     <MainNavbar logoLink='/' />
  <Contact />
 
  <Footer />
  </>
  } />
</Routes>
     
    </>
  );
}

